var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('image-logo'),_c('b-card-title',{staticClass:"mb-2 text-center"},[_vm._v(" Cadastro plataforma CUIDO ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Agora selecione seu tipo de conselho "),_c('br'),_vm._v(" profissional, a unidade federativa e digite seu "),_c('br'),_vm._v(" número do conselho: ")]),_c('validation-observer',{ref:"registerName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-provider',{attrs:{"name":"tipo de conselho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de conselho","label-for":"country"}},[_c('v-select',{ref:"select",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOption,"label":"name","autofocus":""},on:{"search:blur":_vm.validateState},model:{value:(_vm.typeBoard),callback:function ($$v) {_vm.typeBoard=$$v},expression:"typeBoard"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Nenhum registro encontrado. ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label-for":"name","label":"Selecione a UF"}},[_c('validation-provider',{attrs:{"name":"UF de conselho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"code","options":_vm.ufOption},on:{"search:blur":_vm.validateState},model:{value:(_vm.ufBoard),callback:function ($$v) {_vm.ufBoard=$$v},expression:"ufBoard"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Nenhum registro encontrado. ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"name","label":"Digite aqui o número do conselho"}},[_c('validation-provider',{attrs:{"name":"número do conselho","rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"register-number","state":errors.length > 0 ? false:null},model:{value:(_vm.registerNumber),callback:function ($$v) {_vm.registerNumber=$$v},expression:"registerNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-2"},[_vm._v(" Você poderá consultar dados compartilhados pelos seus pacientes vinculados ao seu perfil. ")]),_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"termo de uso","rules":"term"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-2 mb-2",attrs:{"value":"1"},model:{value:(_vm.selectedAgreement),callback:function ($$v) {_vm.selectedAgreement=$$v},expression:"selectedAgreement"}},[_vm._v(" Eu aceito o "),_c('b-link',{attrs:{"href":"https://cucohealth.com/termo-de-uso-plataforma","target":"_blank"}},[_c('u',[_vm._v("Termo de uso")])]),_vm._v(" e a "),_c('b-link',{attrs:{"href":"https://cucohealth.com/politica-privacidade","target":"_blank"}},[_c('u',[_vm._v("Política de privacidade")])]),_vm._v(". "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('alert-message',{attrs:{"status":_vm.status}}),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","type":"submit","block":"","disabled":_vm.loading || invalid},on:{"click":_vm.validationForm}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"small":""}}),_vm._v(" CONTINUAR ")],1),_c('div',{staticClass:"mt-2 text-center"},[_c('b-link',{attrs:{"to":{name:'register-name'}}},[_c('u',[_vm._v("VOLTAR")])])],1)],1)]}}])}),_c('b-progress',{staticClass:"progress-bar-primary mt-2",attrs:{"value":"57.16","max":"100","variant":"primary"}})],1),_c('b-modal',{attrs:{"id":"modal-scrollable","title":"Termo de Uso","modal-class":"modal-primary","height":"50px","ok-only":"","centered":"","size":"lg"}},_vm._l((_vm.agreement),function(content,index){return _c('b-card-text',{key:index},[_vm._v(" "+_vm._s(content)+" ")])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }