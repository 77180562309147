<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <image-logo />

        <b-card-title class="mb-2 text-center">
          Cadastro plataforma CUIDO
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Agora selecione seu tipo de conselho
          <br>
          profissional, a unidade federativa e digite seu
          <br>
          número do conselho:
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="registerName"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- type -->
            <validation-provider
              #default="{ errors }"
              name="tipo de conselho"
              rules="required"
            >
              <b-form-group
                label="Tipo de conselho"
                label-for="country"
              >
                <v-select
                  ref="select"
                  v-model="typeBoard"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOption"
                  label="name"
                  autofocus
                  @search:blur="validateState"
                >
                  <div slot="no-options">
                    Nenhum registro encontrado.
                  </div>
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- UF -->
            <b-form-group
              label-for="name"
              label="Selecione a UF"
            >
              <validation-provider
                #default="{ errors }"
                name="UF de conselho"
                rules="required"
              >
                <v-select
                  v-model="ufBoard"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="ufOption"
                  @search:blur="validateState"
                >
                  <div slot="no-options">
                    Nenhum registro encontrado.
                  </div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- number -->
            <b-form-group
              label-for="name"
              label="Digite aqui o número do conselho"
            >
              <validation-provider
                #default="{ errors }"
                name="número do conselho"
                rules="required|regex:^([0-9]+)$"
              >
                <b-form-input
                  id="name"
                  v-model="registerNumber"
                  name="register-number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="mt-2">
              Você poderá consultar dados compartilhados pelos seus pacientes vinculados ao seu perfil.
            </div>

            <b-form-group
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="termo de uso"
                rules="term"
              >
                <b-form-checkbox
                  v-model="selectedAgreement"
                  value="1"
                  class="mt-2 mb-2"
                >
                  Eu aceito o
                  <b-link
                    href="https://cucohealth.com/termo-de-uso-plataforma"
                    target="_blank"
                  >
                    <u>Termo de uso</u>
                  </b-link> e a
                  <b-link
                    href="https://cucohealth.com/politica-privacidade"
                    target="_blank"
                  >
                    <u>Política de privacidade</u>
                  </b-link>.
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-checkbox>

              </validation-provider>
            </b-form-group>

            <alert-message :status="status" />

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              class="mt-1"
              :disabled="loading || invalid"
              @click="validationForm"
            >
              <b-spinner
                v-show="loading"
                small
              />
              CONTINUAR
            </b-button>

            <div class="mt-2 text-center">
              <b-link
                :to="{name:'register-name'}"
              >
                <u>VOLTAR</u>
              </b-link>
            </div>
          </b-form>
        </validation-observer>
        <b-progress
          value="57.16"
          max="100"
          class="progress-bar-primary mt-2"
          variant="primary"
        />
      </b-card>

      <b-modal
        id="modal-scrollable"
        title="Termo de Uso"
        modal-class="modal-primary"
        height="50px"
        ok-only
        centered
        size="lg"
      >
        <b-card-text
          v-for="(content,index) in agreement"
          :key="index"
        >
          {{ content }}
        </b-card-text>
      </b-modal>

    </div>
  </div>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import {
  BButton, BForm, BFormCheckbox, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BModal, BProgress, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import ImageLogo from '@/layouts/components/ImageLogo.vue'
import AlertMessage from '@/layouts/components/AlertMessage.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BModal,
    BProgress,
    BSpinner,
    vSelect,
    ImageLogo,
    AlertMessage,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      registerNumber: '',
      selectedAgreement: false,
      typeBoard: null,
      ufBoard: null,
      typeOption: [],
      ufOption: [],
      agreement: [],
      agreementId: '',
      status: {},
      // validation rules
      required,

      loading: false,
    }
  },

  computed: {
    computedForm() {
      return this.$refs.registerName
    },
  },

  created() {
    // data
    this.$http.get('registration/type/enumerate')
      .then(response => {
        this.typeOption = (response.data.data).sort((a, b) => a.localeCompare(b))
      })

    this.$http.get('state/enumerate')
      .then(response => {
        this.ufOption = (response.data.data.states_info).sort((a, b) => a.code.localeCompare(b.code))
      })

    this.$http.get('/agreement')
      .then(response => {
        this.agreementId = response.data.data.data.agreement_info.id
        this.agreement = (response.data.data.data.agreement_info.content).split('\n')
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'])
    this.typeBoard = this.$store.state.register.registrationType
    this.ufBoard = this.$store.state.register.registrationtState
    this.registerNumber = this.$store.state.register.registrationCode

    extend('term', {
      ...required,
      validate: () => {
        if (this.selectedAgreement !== false) {
          return true
        }
        return 'O aceite aos termos de uso é obrigatório'
      },
    })
  },

  methods: {
    validationForm() {
      this.$refs.registerName.validate().then(success => {
        if (success) {
          this.loading = true

          this.$store.commit('register/UPDATE_TYPE', this.typeBoard)
          this.$store.commit('register/UPDATE_STATE', this.ufBoard)
          this.$store.commit('register/UPDATE_REGISTRATION_CODE', this.registerNumber)
          this.$store.commit('register/UPDATE_AGREEMENT', this.agreementId)

          this.$store.dispatch('register/registrationInfoValidate', { registrationInfo: { code: this.registerNumber, type: this.typeBoard, state: this.ufBoard.code } })
            .then(() => {
              this.$router.push('/register-password')
            })
            .catch(error => {
              const object = Object.is(error)
              if (object === true) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'Erro interno. Tente novamente.',
                }
              } else if (error.data.error.code === 200073) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'Dados de conselho inválidos ou já cadastrados.',
                }
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    validateState() {
      this.computedForm.validate()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .card-body {
    padding-bottom: 1px;
}
</style>
